var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"protfolio"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"protfolio-logo"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/protfolio-1.png")}}),_c('div',{staticClass:"u-box-text",on:{"click":function($event){return _vm.open('A')}}},[_vm._m(2)])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/protfolio-2.png")}}),_c('div',{staticClass:"u-box-text",on:{"click":function($event){return _vm.open('B')}}},[_vm._m(3)])]),_c('div',{staticClass:"item item-blue"},[_c('img',{staticClass:"ba",attrs:{"src":require("@/assets/images/protfolio-3.png")}}),_c('img',{staticClass:"bg",attrs:{"src":require("@/assets/images/protfolio-3-bg.png")}}),_c('div',{staticClass:"u-box-text",on:{"click":function($event){return _vm.open('C')}}},[_vm._m(4)])]),_c('div',{staticClass:"item"},[_c('img',{staticClass:"img100",attrs:{"src":require("@/assets/images/protfolio-4.png")}}),_c('div',{staticClass:"u-box-text",on:{"click":function($event){return _vm.open('D')}}},[_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{staticClass:"banner-img",attrs:{"src":require("@/assets/images/protfolio-banner.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"protfolio-info"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v("Our Portfolio")]),_c('div',{staticClass:"subtitle"},[_vm._v("ApexMed Ventures Has invested in 5 companies. We work closely with our portfolio to drive value creation.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-text"},[_c('div',{staticClass:"title"},[_vm._v("Elucid Social")]),_c('div',{staticClass:"note"},[_vm._v("Elucid Social integrates businesses with the social good movement through a data-driven strategy that yields measurable results.")]),_c('div',{staticClass:"button"},[_vm._v("Learn more")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-text"},[_c('div',{staticClass:"title"},[_vm._v("HighByte")]),_c('div',{staticClass:"note"},[_vm._v("HighByte focuses in developing DataOps software that tackles data architecture and integration issues encountered by industrial companies.")]),_c('div',{staticClass:"button"},[_vm._v("Learn more")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-text"},[_c('div',{staticClass:"title"},[_vm._v("Covercy")]),_c('div',{staticClass:"note"},[_vm._v("The leading financial OS for the $40 trillion real estate investment management market")]),_c('div',{staticClass:"button"},[_vm._v("Learn more")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-text"},[_c('div',{staticClass:"title"},[_vm._v("NUWA")]),_c('div',{staticClass:"note"},[_vm._v("Nuwa is an advanced AI company specializing in the aesthetic and plastic surgery industry.")]),_c('div',{staticClass:"button"},[_vm._v("Learn more")])])
}]

export { render, staticRenderFns }