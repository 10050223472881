<template>
    <div class="protfolio">
        <div class="banner">
            <img src="@/assets/images/protfolio-banner.png" class="banner-img"/>
        </div>

        <div class="protfolio-info">
            <div class="info">
                <div class="title">Our Portfolio</div>
                <div class="subtitle">ApexMed Ventures Has invested in 5 companies. We work closely with our portfolio to drive value creation.</div>
            </div>
        </div>

        <div class="protfolio-logo">
            <div class="item">
                <img src="@/assets/images/protfolio-1.png"/>
                <div class="u-box-text" @click="open('A')">
                    <div class="u-text">
                        <div class="title">Elucid Social</div>
                        <div class="note">Elucid Social integrates businesses with the social good movement through a data-driven strategy that yields measurable results.</div>
                        <div class="button">Learn more</div>
                    </div>
                </div>
            </div>

            <div class="item">
                <img src="@/assets/images/protfolio-2.png"/>
                <div class="u-box-text" @click="open('B')">
                    <div class="u-text">
                        <div class="title">HighByte</div>
                        <div class="note">HighByte focuses in developing DataOps software that tackles data architecture and integration issues encountered by industrial companies.</div>
                        <div class="button">Learn more</div>
                    </div>
                </div>
            </div>

            <div class="item item-blue">
                <img src="@/assets/images/protfolio-3.png" class="ba"/>
                <img src="@/assets/images/protfolio-3-bg.png" class="bg"/>
                <div class="u-box-text" @click="open('C')">
                    <div class="u-text">
                        <div class="title">Covercy</div>
                        <div class="note">The leading financial OS for the $40 trillion real estate investment management market</div>
                        <div class="button">Learn more</div>
                    </div>
                </div>
            </div>

            <div class="item">
                <img src="@/assets/images/protfolio-4.png" class="img100"/>
                <div class="u-box-text" @click="open('D')">
                    <div class="u-text">
                        <div class="title">NUWA</div>
                        <div class="note">Nuwa is an advanced AI company specializing in the aesthetic and plastic surgery industry.</div>
                        <div class="button">Learn more</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        open(url) {
            this.$router.push("/protfolio"+url)
        }
    }
}
</script>

<style scoped lang="scss">
.protfolio-info {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 405px;

    background-color: #FFFFFF;

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        height: 155px;
    }

    .title {
        font-size: 48px;
        line-height: 31px;
        font-weight: 300;
        color: #050505;
    }

    .subtitle {
        font-size: 22px;
        line-height: 38px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 1px;
        color: #1E1E1E;
    }
}

.protfolio-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
    /*height: 625px;*/
    padding: 95px 60px 75px 60px;
    background-color: #F1F2F7;

    .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 420px;
        height: 420px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #FFFFFF;

        > img {
            width: 281px;
            height: 281px;
        }

        > .img100 {
            width: 100%;
            height: 100%;
        }

        .u-box-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            position: absolute;
            top: 0;
            left: 0;
            transition: all 200ms linear;
            transform: translate(0, 100%);

            width: 100%;
            height: 420px;
            color: #FFFFFF;
            padding-top: 64px;
            background-color: rgba(0, 69, 122, 0.9);
            z-index: 10;
        }

        .u-text {
            width: 328px;

            .title {
                font-size: 32px;
                font-weight: 400;
                line-height: 31px;
                margin-bottom: 32px;
            }

            .note {
                font-size: 22px;
                font-weight: 300;
                letter-spacing: 1px;
                color: #d5d5d5;
            }

            .button {
                position: absolute;
                bottom: 71px;
                left: 46px;
                font-size: 22px;
                font-weight: 400;
                line-height: 31px;
            }
        }

        &:hover {
            cursor: pointer;

            .u-box-text {
                transform: translate(0, 0);
            }
        }
    }

    .item-blue {
        background-color: #3247EF;

        &:hover {
            background-color: #FFFFFF;

            & > img.ba {
                display: none;
            }

            & > img.bg {
                display: flex;
            }
        }

        > img.ba {
            display: flex;
        }

        > img.bg {
            display: none;
            width: 208px;
            height: 208px;
        }
    }
}
</style>